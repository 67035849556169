.Header {
    font-size: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid black;
    padding: 20px;
    height: 150px;
}

.Header > a {
    text-decoration: none;
    color: black;
}