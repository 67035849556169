* {
    box-sizing: border-box;
}

#addFolderButton {
    width: 100px;
    height: 30px;
}

.cancel {
    width: 100px;
    height: 30px;
    border: 1px solid black;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
}

.addFolderForm {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.addFolderControls {
    display: flex;
    flex-direction: row;
    margin-top: 5px;
    padding: 5px;
}
