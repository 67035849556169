.notes {
    list-style: none;
    width: 95%;
}

.note-link {
    text-decoration: none;
}

.note-box {
    border: 1px solid black;
    padding: 5px;
    margin-top: 5px;
}

.Main {
    /* border-left: 1px solid black; */
    width: 100%;
}

.notes > li {
    border: 1px solid black;
    margin-top: 5px;
    padding: 0px 10px;
}

.notes-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.addNoteButton {
    text-decoration: none;
    border: 1px solid black;
    width: 100px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}