.sidebar-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 0px;
}

.folder {
    list-style: none;
    /* border: 1px solid black; */
    outline: 1px solid black;
    padding: 15px;
    min-width: 150px;
}

.folder-link {
    text-decoration: none;
}

.add-folder {
    width: 95%;
    height: 50px;
    margin-top: 5px;
    text-decoration: none;
    border: 1px black solid;
    display: flex;
    justify-content: center;
    align-items: center;
}

.go-back {
    width: 150px;
    height: 50px;
}

.selected {
    background: rgb(136, 217, 253);
    list-style: none;
    /* border: 1px solid black; */
    outline: 1px solid black;
    padding: 15px;
    min-width: 150px;
}